import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

function Contact() {
  const [showSuccess, setShowSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const userMessage = new FormData(event.currentTarget);

    const data = {
      email: userMessage.get("email"),
      firstName: userMessage.get("firstName"),
      lastName: userMessage.get("lastName"),
      message: userMessage.get("message"),
    };

    const sendEmailUrl = `https://mlhjzvywe0.execute-api.ap-southeast-2.amazonaws.com/dev/users/${data.email}/inquiry`;

    try {
      await fetch(sendEmailUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setShowSuccess(true);
    } catch (error) {
      console.log("I am in error");
      console.log(error);
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ m: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="message"
            label="Message"
            id="message"
            multiline
            rows={8}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, backgroundColor: "black" }}
      >
        Send
      </Button>
      {showSuccess && (
        <Alert
          severity="success"
          onClose={() => setShowSuccess(false)}
          sx={{ marginY: 5 }}
        >
          Your message has been successfully sent
        </Alert>
      )}
    </Box>
  );
}

export default Contact;
