import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

function Profile(props) {
  const { user, setUser } = props;

  const [showSuccess, setShowSuccess] = useState(false);

  const handleDeleteAccount = async (event) => {
    event.preventDefault();
   
    const url = "https://mlhjzvywe0.execute-api.ap-southeast-2.amazonaws.com/dev";
    const deleteUserUrl = `${url}/delete/users/${user.userId}`;

    try {
        await fetch(deleteUserUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            }
        });

      setShowSuccess(true);

      setTimeout(() => {
        setUser({})
      }, 3000)

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <h1>
        Hello {user.firstName} {user.lastName}
      </h1>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, backgroundColor: "black" }}
        onClick={handleDeleteAccount}
      >
        Delete account
      </Button>
      {showSuccess && (
        <Alert
          severity="success"
          onClose={() => setShowSuccess(false)}
          sx={{ marginY: 5 }}
        >
          Your account has been successfully deleted
        </Alert>
      )}
    </Box>
  );
}
export default Profile;
