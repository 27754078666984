import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import convoImage from "../convo.png";

function SignIn(props) {
  const { setUser } = props;

  const [showFailure, setShowFailure] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataEntered = new FormData(event.currentTarget);

    const email = dataEntered.get("email");
    const password = dataEntered.get("password");

    const data = {
      password,
    };
    const url =
      "https://mlhjzvywe0.execute-api.ap-southeast-2.amazonaws.com/dev";
    const getUserUrl = `${url}/users/${email}`;

    try {
      const response = await fetch(getUserUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const userData = await response.json();
      console.log(userData);

      if (userData?.status) {
        throw userData;
      }

      setUser(userData);
    } catch (error) {
      console.log(error);
      setShowFailure(true);
    }
  };

  return (
    <>
      <img
        src={convoImage}
        alt="Convo Logo"
        style={{ height: "20%", width: "20%" }}
      />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, backgroundColor: "black" }}
        >
          Sign In
        </Button>
        {showFailure && (
          <Alert
            severity="error"
            onClose={() => setShowFailure(false)}
            sx={{ marginY: 5 }}
          >
            Incorrect email address or password
          </Alert>
        )}
      </Box>
    </>
  );
}
export default SignIn;
