import React, { useState } from "react";
import Box from "@mui/material/Box";

import SignIn from "./Signin";
import Profile from "./Profile";

function Home() {
  const [user, setUser] = useState({});

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        m: 5,
      }}
    >
      {!user.userId ? <SignIn setUser={setUser} /> : <Profile user={user} setUser={setUser} />}
    </Box>
  );
}
export default Home;
