import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Header from "./components/Header"
import Home from "./components/Home"
import Privacy from "./components/Privacy"
import Contact from "./components/Contact"
import AccountDeletion from "./components/AccountDeletion"

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <div>404 Not Found</div>,
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/deletion',
    element: <AccountDeletion />
  },
]);

function App() {
  const sections = [{ title: "Contact", url: "contact" }, { title: "Home", url: "/" }, { title: "Privacy", url: "privacy" }, { title: "Account Deletion", url: "deletion" }]

  return (
    <div className="App">
      <Header sections={sections} title="Convo"/>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
