import * as React from 'react';
import Box from '@mui/material/Box';
import convoImage from "../convo.png" 

function Home() {

  return (
    <Box sx={{ display:"flex", flex: 1, justifyContent:"center", alignItems:"center", flexDirection:"column", marginY:20}}>
        <img src={convoImage} alt="Convo Logo" style={{ height: '20%', width:"20%"}} />
    </Box>
  );
}

export default Home;